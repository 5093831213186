import React, { ReactElement } from "react";
import { graphql, Link } from "gatsby";
import kebabCase from "lodash/kebabCase";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { Layout } from "../components";
import { generateRecipeSchema } from "../utils/generateRecipeSchema";

const StyledPostContainer = styled.main`
  max-width: 1000px;
`;
const StyledPostHeader = styled.header`
  margin-bottom: 50px;
  .tag {
    margin-right: 10px;
  }
`;
const StyledPostContent = styled.div`
  margin-bottom: 100px;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 2em 0 1em;
  }

  p {
    margin: 1em 0;
    line-height: 1.5;
    color: var(--light-slate);
  }

  a {
    ${({ theme }) => theme.mixins.inlineLink};
  }

  code {
    background-color: var(--lightest-navy);
    color: var(--lightest-slate);
    border-radius: var(--border-radius);
    font-size: var(--fz-sm);
    padding: 0.2em 0.4em;
  }

  pre code {
    background-color: transparent;
    padding: 0;
  }
`;

const PostTemplate = ({ data, location }: any): ReactElement => {
  const { frontmatter, html } = data.markdownRemark;
  const receipeSchema = generateRecipeSchema(frontmatter);

  const { title, tags } = frontmatter;

  return (
    <Layout location={location}>
      <Helmet title={title}>
        {receipeSchema && (
          <script type="application/ld+json">
            {JSON.stringify(receipeSchema)}
          </script>
        )}
      </Helmet>

      <StyledPostContainer>
        <span className="breadcrumb">
          <span className="arrow">&larr;</span>
          <Link to="/cooking">Back to the secrets</Link>
        </span>

        <StyledPostHeader>
          <h1 className="medium-heading">{title}</h1>
          <p className="subtitle">
            {tags &&
              tags.length > 0 &&
              tags.map((tag: any, i: any) => (
                <Link
                  key={i}
                  to={`/cooking/tags/${kebabCase(tag)}/`}
                  className="tag"
                >
                  #{tag}
                </Link>
              ))}
          </p>
        </StyledPostHeader>

        <StyledPostContent dangerouslySetInnerHTML={{ __html: html }} />
      </StyledPostContainer>
    </Layout>
  );
};

export type Recipe = {
  title: string;
  description: string;
  image: string;
  recipeYield: string;
  recipeCategory: string;
  recipeCuisine: string;
  prepTime: string;
  cookTime: string;
  recipeIngredient: string[];
  recipeInstructions: string[];
  keywords: string[];
};

export default PostTemplate;

PostTemplate.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

export const pageQuery = graphql`
  query ($path: String!) {
    markdownRemark(frontmatter: { slug: { eq: $path } }) {
      html
      frontmatter {
        title
        description
        slug
        tags
        cookTime
        prepTime
        recipeYield
        recipeCategory
        recipeCuisine
        recipeIngredient
        recipeInstructions
        keywords
      }
    }
  }
`;
