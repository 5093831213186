import type { HowTo, WithContext } from "schema-dts";
import { Recipe } from "../templates/post";

export const generateRecipeSchema = (
  recipe: Recipe
): WithContext<HowTo> | undefined => {
  // Initialize the schema object with mandatory fields
  const schema: WithContext<HowTo> = {
    "@context": "https://schema.org",
    "@type": "Recipe",
  };

  // Add properties to the schema only if they have valid values
  if (recipe.title) schema.name = recipe.title;
  if (recipe.description) schema.description = recipe.description;
  if (recipe.image) schema.image = recipe.image;
  if (recipe.recipeYield) schema.recipeYield = recipe.recipeYield;
  if (recipe.recipeCategory) schema.recipeCategory = recipe.recipeCategory;
  if (recipe.recipeCuisine) schema.recipeCuisine = recipe.recipeCuisine;
  if (recipe.prepTime) schema.prepTime = recipe.prepTime;
  if (recipe.cookTime) schema.cookTime = recipe.cookTime;

  // Only include recipeIngredient and recipeInstructions if they are not empty
  if (Array.isArray(recipe.recipeIngredient) &&
    recipe.recipeIngredient.length > 0) {
    schema.recipeIngredient = recipe.recipeIngredient;
  }
  if (Array.isArray(recipe.recipeInstructions) &&
    recipe.recipeInstructions.length > 0) {
    schema.recipeInstructions = recipe.recipeInstructions.map(
      (instruction) => ({
        "@type": "HowToStep",
        text: instruction,
      })
    );
  }

  // Include keywords if they are available and not empty
  if (Array.isArray(recipe.keywords) && recipe.keywords.length > 0) {
    schema.keywords = recipe.keywords.join(", ");
  }

  // Return the schema if it has more properties than just @context and @type
  return Object.keys(schema).length > 2 ? schema : undefined;
};
